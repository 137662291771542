export default {
	general: {
		save: 'Tallenna',
		cancel: 'Peruuta',
		yes: 'Kyllä',
		no: 'Ei',
		goBack: 'Palaa takaisin',
		changeCookieSettings: 'Muuta evästeasetuksiasi',
	},
	routes: {
		error404: {
			title: 'Sivua ei löytynyt',
		},
		membershipCard: {
			title: 'Kortti',
		},
		myAccount: {
			title: 'Muokkaa tietoja',
			headline: 'Muokkaa tietoja',
		},
		addresses: {
			title: 'Muokkaa osoitetietoja',
			headline: 'Muokkaa osoitetietoja',
		},
		subchapters: {
			title: 'Alaosastot',
			headline: 'Alaosastot',
		},
		degrees: {
			title: 'Tutkinnot',
			headline: 'Tutkinnot',
		},
		jobContainer: {
			title: 'Työpaikat',
			headline: 'Työpaikat',
		},
		discount: {
			title: 'Hae jäsenmaksualennusta',
			headline: 'Hae jäsenmaksualennusta',
		},
		benefitContainer: {
			title: 'Jäsenedut',
			headline: 'Jäsenedut',
		},
		benefit: {
			title: 'Jäsenetu',
			headline: 'Jäsenetu',
		},
		eventContainer: {
			title: 'Tapahtumat',
			headline: 'Tapahtumat',
		},
		event: {
			title: 'Tapahtuma',
			headline: 'Tapahtuma',
		},
		newsContainer: {
			title: 'Ajankohtaista',
			headline: 'Ajankohtaista',
		},
		news: {
			title: 'Uutinen',
			headline: 'Uutinen',
		},
		pageContainer: {
			title: 'Sivut',
			headline: 'Sivut',
		},
		page: {
			title: 'Sivu',
			headline: 'Sivu',
		},
		invoiceContainer: {
			title: 'Laskut',
			headline: 'Laskut',
		},
		invoice: {
			title: 'Lasku',
			headline: 'Lasku',
		},
		settings: {
			title: 'Asetukset',
			headline: 'Asetukset',
		},
	},
	cookieTypes: {
		necessary: 'Välttämättömät',
		statistics: 'Tilastointi',
		marketing: 'Markkinointi',
	},
	validationErrors: {
		required: 'Tämä kenttä on pakollinen.',
		email: 'Tarkista sähköpostiosoitteen muotoilu.',
	},
}
