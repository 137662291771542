<i18n>
	{
	"en": {
		"privyacPolicy": "Read about SAFA's privacy policy"
	},
	"fi": {
		"privyacPolicy": "Lue lisää SAFAN tietosuojasta"
	}
	}
</i18n>

<template>
	<v-container
		class="container--narrow"
	>
		<v-card>
			<v-card-text>
				<SchemaToForm
					:debug="false"
					v-model="formData"
					:schema="this.config.forms.memberDiscountApplication"
					:disabled="formLoading"
					@valid="formValid = true"
					@invalid="formValid = false"
				/>
			</v-card-text>
			<v-divider />
			<v-card-text>
				<v-spacer />
				<v-btn
					color="secondary"
					text
					href="https://www.safa.fi/tietosuoja"
					target="_blank"
				>
					{{ $i18n.t('privyacPolicy') }}
				</v-btn>
			</v-card-text>
			<v-divider />
			<v-card-actions>
				<v-spacer />
				<v-btn
					color="primary"
					:loading="formLoading"
					:disabled="formLoading || !formValid"
					@click="save"
				>
					{{ $i18n.t('general.save') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'Discount',

	data: () => ({
		formData: {
			c_periods: [],
			c_acceptance_of_the_privacy_policy: false,
		},
		formLoading: false,
		formValid: false,
	}),

	computed: {
		...mapState([
			'user',
			'config',
		]),
	},

	methods: {
		save () {
			this.formLoading = true

			this.$api.Me.doRequest({
				url: 'discount',
				method: 'POST',
				body: this.formData,
			}).on('done', (res) => {
				if (res.body.error) return

				const parentRoute = this.$route.matched[this.$route.matched.length - 2]
				this.$router.replace(parentRoute)
			}).on('finish', () => {
				this.formLoading = false
			})
		},
	},
}
</script>
